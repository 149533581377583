<template>
    <list-table-layout 
        header="Inspections"
        :columns="columns"
        :model="InspectionModel"
        :records="inspections"
        class="inspection-list"
        >
        <template v-slot:rowcontrols="{row}">
            <a class="button" :href="row['report']"><i class="far fa-file"></i> Report</a>
        </template>
    </list-table-layout>
</template>

<script>
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'

import InspectionModel from '../../models/InspectionModel.js'

import Locations from '../../models/Locations';
import Directions from '../../models/Directions';
import Reasons from '../../models/Reasons';

import { ref } from '@vue/reactivity';
import database from "@/store/database";



const columns = {
    'date' : {
        title : "Date",
        sortable: true
    },
    'account' : {
        title: "Account #",
        sortable: true
    },
    'location' : {
        title: "Location",
        sortable: true,
        options: Locations
    },
    'trailer-number' : {
        title: "Trailer #",
        sortable: true
    },
    'lease-number' : {
        title: "Lease #",
        sortable: true,
    },
    'direction' : {
        title: "Direction",
        sortable: true, 
        options: Directions
    },
    'reason' : {
        title: "Reason",
        sortable: true,
        options: Reasons
    },

};

export default {
    components: {
        ListTableLayout
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const inspections = database[InspectionModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {inspections, loaded, InspectionModel, columns}

    },
}
</script>
<style lang="scss">
.inspection-list{
    .magic-alignment{
        th:last-child{
            width: 135px;
        }
    }
}
</style>
