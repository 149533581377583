import Model from './Model.js';


class InspectionModel extends Model {
    constructor(values, config = {}){
        super(config);

        this.prop('id', Number, true);

        this.populate(values, config);

    }
    static name = 'InspectionModel';
    static source = 'Inspection';
}
window[Symbol.for(InspectionModel.name)] = InspectionModel;

export default InspectionModel;